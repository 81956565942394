import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Import sections
import LeftSection from "./leftSection";
import RightSection from "./rightSection";
import MobileRightSection from "./mobileSlider";
import { setSectionTitle, setStartTimer, setAssessmentID, setCurrentSectionIndex, setNoOfQuestions, setCurrentQuestion, setExamDuration, setCurrentQuestionIndex } from "../../slices/authSlice";
//data
import {
  filterQuestionsByCategoryMultiStatus,
  filterQuestionsByCategoryMultiStatus2,
  filterValidAnswers,
  getTotalResponseCount,
  isAnswerPresentForCurrentQuestion,
  McqsJsonData,
  removeAnswerForCurrentQuestion,
  removeLocalStorageItems,
  transformAnswerToPayload,
} from "../../utils/commonHelper";
import { stubsData } from "../../utils/jsonHelper";
import {
  setConfirmationModal,
  setSuccessModal,
  setTimerModal,
} from "../../slices/modalSlice";
import {
  useAddDataMutation,
  useDownloadFileQuery,
  useGetListQuery,
} from "../../apiSlices/question_submission_ApiSlice";
//modal
import ConfirmationModal from "./modal/ConfirmationModal";
import SuccessModal from "./modal/SuccessModal";
import Loader from "../Loader";
import TimerModal from "./modal/TImerModal";

const Index = ({
  isConfirmationModal,
  isSuccessModal,
  isTimerCompletedModal,
}) => {
  //misc
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.authReducer); // store data
  const navigate = useNavigate();
  //query n mutation

  const payload = {
    classID: userDetails?.classID,
    board: userDetails?.board,
    language: userDetails?.language
  }

  const {
    data: questionsData,
    error: questionsError,
    isLoading: questionLoading,
  } = useDownloadFileQuery(payload);

  // const questionsData = useSelector((state) => state.authReducer.allquestions);
  const [addData, { isLoading: isLoadingAddData, isErrorAddData, isSuccess }] =
    useAddDataMutation();

  // state
  //count
  const [totalResponseCount, setTotalResponseCount] = useState(0);

  const [allQuestions, setAllQuestions] = useState([]);
  const [lastQuestionOfLastCategory, setLastQuestionOfLastCategory] =
    useState(null);
  const [currentSectionType, setCurrentSectionType] = useState("");
  // const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const currentSectionIndex = useSelector((state) => state.authReducer.currentSectionIndex);
  // const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  // const [currentQuestion, setCurrentQuestion] = useState(null);
  const currentQuestion = useSelector((state) => state.authReducer.currentQuestion);
  const currentQuestionIndex = useSelector((state) => state.authReducer.currentQuestionIndex);
  const [lastQuestion, setLastQuestion] = useState(null);
  const [answers, setAnswers] = useState(() => {
    const savedAnswers = localStorage.getItem("answers");
    return savedAnswers ? JSON.parse(savedAnswers) : [];
  });
  const [storeAnswer, setStoreAnswer] = useState("");
  const [justifyText, setJustifyText] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  //filter
  const [isFilter, setIsFilter] = useState(false);
  const [filterStatus, setFilterStatus] = useState([]);
  const [filteredQuestion, setFilteredQuestion] = useState([]);
  const questionImages = localStorage.getItem("imageURLS");

  //isInfo
  const [isInfo, setIsInfo] = useState(false);
  //methods
  const handleNext = (save, markedForReview, withoutJustification) => {
    const section =
      allQuestions.questionsListAsPerCategory[currentSectionIndex];
    setLastQuestion(currentQuestion);
    if (save) {
      // console.log("called");
      setAnswers((prevAnswers) => {
        const updatedAnswers = [...prevAnswers];
        if (!updatedAnswers[currentSectionIndex]) {
          updatedAnswers[currentSectionIndex] = [];
        }

        const answered_Justify_And_For_Review =
          storeAnswer !== "" && justifyText !== "" && markedForReview;
        const not_Answered_And_InReview =
          storeAnswer === "" && justifyText === "" && markedForReview;
        const answered_NotJustified_InReview =
          storeAnswer !== "" && justifyText === "" && markedForReview;

        const existingIndex = updatedAnswers[currentSectionIndex].findIndex(
          obj => obj.currentQuestion.question_id === currentQuestion.question_id
        );

        const newObject = {
          answer: storeAnswer,
          saved: true,
          currentSectionType,
          justifyText,
          skipped: storeAnswer === "" && !markedForReview ? true : false,
          
          answered_And_Justified:
            storeAnswer !== "" &&
            (justifyText !== "" || withoutJustification) &&
            !markedForReview
              ? true
              : false,

          answered_ButNot_Justified:
            storeAnswer !== "" && justifyText === "" && !markedForReview && !withoutJustification
              ? true
              : false,
          
          markedForReview:
            answered_Justify_And_For_Review ||
            not_Answered_And_InReview ||
            answered_NotJustified_InReview ||
            false,
          currentQuestion,
        };

        if (existingIndex !== -1) {
          updatedAnswers[currentSectionIndex][existingIndex] = newObject;
        } else {
          updatedAnswers[currentSectionIndex].push(newObject);
        }

        setStoreAnswer("");
        setJustifyText("");
        return updatedAnswers;
      });
    }

    // Navigation logic
    if (currentQuestionIndex < section.questions.length - 1) {
      dispatch(setCurrentQuestionIndex(currentQuestionIndex + 1));
      dispatch(setCurrentQuestion(section.questions[currentQuestionIndex + 1]));
    } else if (
      currentSectionIndex <
      allQuestions.questionsListAsPerCategory.length - 1
    ) {
      const nextSectionIndex = currentSectionIndex + 1;
      dispatch(setCurrentSectionIndex(nextSectionIndex));
      dispatch(setCurrentQuestionIndex(0));
      dispatch(setCurrentQuestion(
        allQuestions.questionsListAsPerCategory[nextSectionIndex].questions[0]
      ));
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      dispatch(setCurrentQuestionIndex(currentQuestionIndex - 1));
      dispatch(setCurrentQuestion(
        allQuestions.questionsListAsPerCategory[currentSectionIndex].questions[
          currentQuestionIndex - 1
        ]
      ));
    } else if (currentSectionIndex > 0) {
      // Move to the previous section
      const prevSectionIndex = currentSectionIndex - 1;
      const prevSection =
        allQuestions.questionsListAsPerCategory[prevSectionIndex];
      dispatch(setCurrentSectionIndex(prevSectionIndex));
      dispatch(setCurrentQuestionIndex(prevSection.questions.length - 1));
      dispatch(setCurrentQuestion(
        prevSection.questions[prevSection.questions.length - 1]
      ));
    }
  };

  const handleStoreAnswer = (data) => {
    // console.log(data, " dddd");
    setStoreAnswer(data);
  };

  const handleShowCurrentQues = (currentQuestionIndex, currentSectionIndex) => {
    // console.log({
    //   currentQuestionIndex,
    //   currentSectionIndex,
    // });
    dispatch(setCurrentQuestionIndex(currentQuestionIndex));
    dispatch(setCurrentSectionIndex(currentSectionIndex));

    const newCurrentQuestion =
      allQuestions.questionsListAsPerCategory[currentSectionIndex].questions[
        currentQuestionIndex
      ];
    dispatch(setCurrentQuestion(newCurrentQuestion));
  };

  //submit

  //   {
  //     "fullName": "Abdul Sayed",
  //     "rollNumber": "12",
  //     "classID": "class_8",
  //     "schoolName": "dfghj",
  //     "board": "ICSE",
  //     "phoneNo": "+918296708008",
  //     "email": "sdcxzsakarim9124@gmail.com"
  // }

  const handleSubmit = async () => {
    const { fullName, rollNumber, classID, schoolName, board, phoneNo, email, section } =
      userDetails;
    const assessment_id = `assessment_${classID.split('_')[1]}`
    const userObj = {
      student_id: fullName,
      name: fullName,
      class: classID,
      roll_number: rollNumber,
      schoolName: schoolName,
      schoolId: schoolName,
      email,
      board,
      phoneNo,
      section: section,
      assessment_id: assessment_id,
      uniqueId: Math.random().toString(),
    };

    try {
      const payload = transformAnswerToPayload(userObj, answers);
      console.log({ payload, userDetails }, " payload");
      await addData(payload);
      dispatch(setConfirmationModal(false));
      dispatch(setTimerModal(false));
      // dispatch(setSuccessModal(true));
      navigate("/review", { replace: true });
      const keysToRemove = ["answers", "remainingTime", "userDetails", "showIntro", "ass"];

      removeLocalStorageItems(keysToRemove);
      localStorage.clear()
      // Trigger the storage event manually
      window.dispatchEvent(new Event("storage"));
      ///cleanup
      console.log("Data uploaded successfully");
    } catch (error) {
      dispatch(setConfirmationModal(false));
      dispatch(setTimerModal(false));
      dispatch(setSuccessModal(true));
      console.error("Failed to upload data:", error);
    }
    // setTimeout(() => {
    // }, 1000);
  };

  // async
  //set current section type
  useEffect(() => {
    if (allQuestions && allQuestions?.questionsListAsPerCategory?.length > 0) {
      const sectionTile =
        allQuestions.questionsListAsPerCategory[currentSectionIndex];
      setCurrentSectionType(sectionTile?.category);
      dispatch(setSectionTitle(sectionTile?.category));
      // console.log(sectionTile, " titllle");
    }
  }, [currentSectionIndex, dispatch, allQuestions]);

  //populate the answer
  useEffect(() => {
    const currentAnswers = answers[currentSectionIndex] || [];
    for (let i = 0; i < currentAnswers.length; i++) {
       const currentAnswer = currentAnswers[i];
       if (currentAnswer.currentQuestion.question_id === currentQuestion.question_id) {
         // If there is an answer stored, set it to storeAnswer
         setStoreAnswer(currentAnswer.answer);
         setJustifyText(currentAnswer.justifyText);
         break; // Exit the loop once the condition is satisfied
       } else {
         // Reset to empty if there's no answer when the question is revisited
         setStoreAnswer("");
         setJustifyText("");
       }
    }
  }, [currentQuestionIndex, currentSectionIndex, answers]);

  //set the first question
  useEffect(() => {
    if (allQuestions && allQuestions?.questionsListAsPerCategory?.length > 0) {
      const lastSection =
        allQuestions.questionsListAsPerCategory[
          allQuestions.questionsListAsPerCategory.length - 1
        ];
      if (lastSection.questions.length > 0) {
        const lastQuestion =
          lastSection.questions[lastSection.questions.length - 1];
        setLastQuestionOfLastCategory(lastQuestion);
      }

      // Set the first question of the first section as the current question
      if (currentQuestion === null) {
        const firstSection = allQuestions.questionsListAsPerCategory[0];
        if (firstSection.questions.length > 0) {
          dispatch(setCurrentQuestion(firstSection.questions[0]));
        }
      }
    }
  }, [allQuestions]); // Dependency only on sections array

  //isSubmitHandler
  useEffect(() => {
    if (isSubmit && currentQuestion && answers?.length) {
      console.log(answers, " annnn");
      // handleSubmit(answers);
      setIsSubmit(false);
      dispatch(setConfirmationModal(true));
    }
  }, [isSubmit, answers, currentQuestion, dispatch]);

  //filteredQues
  useEffect(() => {
    if (allQuestions && allQuestions?.questionsListAsPerCategory?.length) {
      const filteredData = filterQuestionsByCategoryMultiStatus(
        filterStatus,
        answers,
        allQuestions
      );
      // console.log(filterStatus);
      setFilteredQuestion({
        ...allQuestions,
        questionsListAsPerCategory: filteredData,
      });
    }
  }, [filterStatus, answers, allQuestions]);

  //store answers in browser Storage
  useEffect(() => {
    localStorage.setItem("answers", JSON.stringify(answers));
    if (answers) {
      const totalResponseCount = getTotalResponseCount(
        transformAnswerToPayload({}, answers).responses
      );
      setTotalResponseCount(totalResponseCount);
    }
  }, [answers]);

  useEffect(() => {
    if (questionsData) {
      setAllQuestions(questionsData?.body ?? []);
      dispatch(setAssessmentID(questionsData?.body.assessment_id));

      const noOfQuestions = questionsData?.body?.questionsListAsPerCategory.map(section => [section.category, section.questions.length]);
      dispatch(setNoOfQuestions(noOfQuestions));
      // Assuming currentQuestion and dispatch are available in the current scope
      if (currentQuestion === null) {
        dispatch(setCurrentQuestion(questionsData.body.questionsListAsPerCategory[0].questions[0]));
      }
      const assessmentDuration = parseInt(questionsData?.body?.assessment_duration, 10);
      dispatch(setExamDuration(assessmentDuration));
      localStorage.setItem("startTimer", true);
      dispatch(setStartTimer(true));
    }
  }, [questionsData, dispatch]);

  //Loading n Error Handling
  if (questionLoading || isLoadingAddData) return <Loader />;
  if (questionLoading || isErrorAddData)
    return <div>Error: {questionsError?.message}</div>;

  return (
    <div
      className="row justify-content-between"
      style={{ "--bs-gutter-x": "0rem", position: "relative" }}
    >
      <LeftSection
        currentSectionIndex={currentSectionIndex}
        currentQuestion={currentQuestion}
        currentQuestionIndex={currentQuestionIndex}
        answers={answers}
        storeAnswer={storeAnswer}
        handleStoreAnswer={handleStoreAnswer}
        //
        justifyText={justifyText}
        setJustifyText={setJustifyText}
        //
        withoutJustificationIndexes={
          allQuestions?.without_justification_questions || []
        }
      />
      <RightSection
        allQuestions={
          filteredQuestion?.questionsListAsPerCategory?.length
            ? filteredQuestion
            : allQuestions
        }
        currentSectionType={currentSectionType}
        currentQuestionIndex={currentQuestionIndex}
        currentSectionIndex={currentSectionIndex}
        handleShowCurrentQues={handleShowCurrentQues}
        //
        currentQuestion={currentQuestion}
        lastQuestionOfLastCategory={lastQuestionOfLastCategory}
        handleNext={handleNext}
        handleSubmit={handleSubmit}
        //color
        answers={answers}
        lastQuestion={lastQuestion}
        //isSubmission
        setIsSubmit={setIsSubmit}
        //isInfo
        isInfo={isInfo}
        setIsInfo={setIsInfo}
        //filter
        isFilter={isFilter}
        setIsFilter={setIsFilter}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
      />

      <MobileRightSection
        allQuestions={
          filteredQuestion?.questionsListAsPerCategory?.length
            ? filteredQuestion
            : allQuestions
        }
        currentSectionType={currentSectionType}
        currentQuestionIndex={currentQuestionIndex}
        currentSectionIndex={currentSectionIndex}
        handleShowCurrentQues={handleShowCurrentQues}
        //
        currentQuestion={currentQuestion}
        lastQuestionOfLastCategory={lastQuestionOfLastCategory}
        handleNext={handleNext}
        handleSubmit={handleSubmit}
        //color
        answers={answers}
        lastQuestion={lastQuestion}
        //isSubmission
        setIsSubmit={setIsSubmit}
        //isInfo
        isInfo={isInfo}
        setIsInfo={setIsInfo}
        //filter
        isFilter={isFilter}
        setIsFilter={setIsFilter}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
      />  

      <div className="p-3 switch_btns_container">
        {isAnswerPresentForCurrentQuestion(currentQuestion, answers) ? (
          <button
            type="button"
            id="previousBtn"
            className="btn me-sm-2 waves-effect waves-light"
            onClick={() =>
              removeAnswerForCurrentQuestion(
                currentQuestion,
                answers,
                setAnswers
              )
            }
          >
            Clear
          </button>
        ) : (
          <button
            type="button"
            id="previousBtn"
            className="btn me-sm-2 waves-effect waves-light"
            disabled
          >
            Clear
          </button>
        )}

        <button
          type="button"
          id="previousBtn"
          className="btn  me-sm-2 waves-effect waves-light"
          onClick={handlePrevious}
        >
          Previous
        </button>
        {currentQuestion?.question_id !==
        lastQuestionOfLastCategory?.question_id ? (
          <>
            <button
              type="button"
              id="markForReviewBtn"
              className="btn me-sm-2 waves-effect waves-light"
              // onClick={() => handleNext(false)} // Next without saving
              onClick={() => handleNext(true, true)} // Next without saving
            >
              Next & Mark for review
            </button>
            <button
              type="button"
              id="nextBtn"
              className="btn me-sm-2 waves-effect waves-light"
              // onClick={() => handleNext(true)} // Save and move to next question
              onClick={() => {
                const justificationNotRequired = allQuestions?.without_justification_questions.includes(currentQuestion?.question_id) ? true : false;
                handleNext(true, undefined, justificationNotRequired);
              }}
            >
              Next & Save
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              id="markForReviewBtn"
              className="btn me-sm-2 waves-effect waves-light"
              // onClick={() => handleNext(false)} // Next without saving
              onClick={() => handleNext(true, true)} // Next without saving
            >
              Next & Mark for review
            </button>
            <button
              type="button"
              id="nextBtn"
              className="btn me-sm-2 waves-effect waves-light"
              // onClick={() => handleNext(true)} // Save and move to next question
              onClick={() =>
                handleNext(
                  true,
                  undefined,
                  allQuestions?.without_justification_questions.includes(currentQuestion?.question_id)
                    ? true
                    : false
                )
              }
            >
              Save
            </button>
          </>
        )}
        {/* <button
          onClick={() =>
            console.log(
              {
                // allQuestions,
                currentQuestion,
                // currentQuestionIndex,
                // currentSectionIndex,
                // currentSectionType,
                // lastQuestionOfLastCategory,
                answers,
                // storeAnswer,
                // justifyText,
                // lastQuestion,
                // filtered: filterQuestions("skipped", answers, allQuestions),
                // filtered: filterQuestionsByCategory(
                //   "skipped",
                //   answers,
                //   allQuestions
                // ),
                // filtered: filterQuestionsByCategoryMultiStatus(
                //   [null],
                //   answers,
                //   allQuestions
                // ),
                filtered2: filterQuestionsByCategoryMultiStatus(
                  // ["not_visited"],
                  ["not_visited", "Skipped"],
                  answers,
                  allQuestions
                ),
              },
              " tttt"
            )
          }
        >
          TEST
        </button> */}
        {/* <button
          // onClick={() =>
          //   console.log({
          //     McqsJsonData,
          //     stubsData,
          //   })
          // }
          onClick={() =>
            console.log(
              // transformAnswerToPayload(
              //   {
              //     student_id: "",
              //     name: "Rahul",
              //     class: "10",
              //     section: "A",
              //     roll_number: "12",
              //     assessment_id: "C10Sc30",
              //     uniqueId: "new",
              //     schoolName: "new",
              //     schoolId: "new",
              //   },
              //   answers
              // ),
              totalResponseCount,
              allQuestions,
              " ress"
            )
          }
        >
          response
        </button> */}
        {/* <button
          onClick={() =>
            console.log({ filterStatus, filteredQuestion }, " ffff")
          }
        >
          Filter
        </button> */}
      </div>

      {/* modal */}

      {isConfirmationModal ? (
        <ConfirmationModal
          title={"Are you sure, you want to submit ?"}
          text={"Hello TExt"}
          onClose={() => {}}
          allQuestions={allQuestions}
          answer={answers}
          //count
          totalResponseCount={totalResponseCount}
          //submit
          handleSubmit={handleSubmit}
        />
      ) : null}

      {isSuccessModal ? (
        <SuccessModal
          title={"Successfully submitted"}
          text={"Hello TExt"}
          onClose={() => {}}
        />
      ) : null}

      {isTimerCompletedModal ? (
        <TimerModal handleSubmit={handleSubmit} />
      ) : null}

      {/* <Loader /> */}
    </div>
  );
};

export default Index;
