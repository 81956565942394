import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { setUserDetails, setUserInfoTrue } from "../../slices/authSlice";
import InputBox from "../../components/InputFields/InputBox";
import SelectBox from "../../components/InputFields/SelectBox";
import illustration from "../../assets/images/backgrounds/registration.svg";
import IndiaFlag from "../../assets/images/elements/flag.png";
import { removeLocalStorageItems } from "../../utils/commonHelper";
import { useRegisterUserMutation, useGetListQuery } from "../../apiSlices/question_submission_ApiSlice";
import { isValidPhoneNumber } from "libphonenumber-js";
import ShowToast from "../../components/Toast";

const Register = () => {
  const [classoptions, setClassOptions] = useState([]);

  const [classoptionsisLoading, setClassOptionsIsLoading] = useState(true);
  //queries n mutation
  const [registerUser, { isLoading, isSuccess, isError, error }] = useRegisterUserMutation();
  //misc
  const dispatch = useDispatch();
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check the initial screen size

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //state
  const [formData, setFormData] = useState({
    fullName: "",
    rollNumber: "",
    language: "English",
    classID: "",
    schoolName: "",
    board: "",
    phoneNo: "",
    guardianPhoneNo: "",
    email: "",
    section: ""
  });

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const { fullName, rollNumber, classID, schoolName, board, phoneNo, guardianPhoneNo, email, language, section } = formData;

    const isPhoneValid = (phoneNo && isValidPhoneNumber(phoneNo, "IN") && phoneNo.length === 10)||
    (guardianPhoneNo && isValidPhoneNumber(guardianPhoneNo, "IN") && guardianPhoneNo.length === 10);
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    if (
      fullName &&
      rollNumber &&
      classID &&
      schoolName &&
      board &&
      isPhoneValid &&
      email &&
      isEmailValid &&
      section &&
      language
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  // Fetch listData only when formData.language has a value
  const { data: listData, isError: isListError, isLoading: isListLoading } = useGetListQuery({ board: formData.board, language: "English" },
    { skip: !formData.board } );

  // useEffect(() => {
  //   if (listData) {
  //     console.log('List data fetched', listData);
  //     const options = listData.map((filePath) => {
  //       const classNumber = filePath.match(/assessment_(\d+)/)[1]; // Extract class number
  //       return { value: `class_${classNumber}`, label: `Class ${classNumber}` };
  //     });

  //     options.unshift({ value: "", label: "Select" }); // Add first element
  //     setClassOptions(options); // Update state
  //     setClassOptionsIsLoading(false); // Set loading to false
  //     console.log('Class options', options);
  //   }
  // }, [listData]);

  useEffect(() => {
    if (listData) {
      console.log('List data fetched', listData);
      const options = listData.map((filePath) => {
        let classNumber = null;
        let identifier = null;
  
        // Extract class number if it exists
        const classMatch = filePath.match(/assessment_(\d+)/);
        if (classMatch) {
          classNumber = parseInt(classMatch[1]);
        }
  
        // Extract any additional identifier (like board name, subject, etc.)
        // Match anything after 'assessment_' that is not a number, assuming it's the identifier
        const identifierMatch = filePath.match(/assessment_([a-zA-Z]+)/);
        if (identifierMatch) {
          identifier = identifierMatch[1];
        }
  
        // Construct the value and label based on available data
        if (classNumber && identifier) {
          return { value: `class_${classNumber}_${identifier}`, label: `Class ${classNumber} - ${identifier}`,classNumber };
        } else if (classNumber) {
          return { value: `class_${classNumber}`, label: `Class ${classNumber}`,classNumber };
        } else if (identifier) {
          return { value: `class_${identifier}`, label: `${identifier}` };
        }
  
        // If neither class nor identifier is found, we return null
        return null;
      }).filter((option) => option !== null) // Remove null values
      .sort((a, b) => {
        // Sort only if classNumber exists; move options without classNumber to the end
        if (a.classNumber != null && b.classNumber != null) {
          return a.classNumber - b.classNumber;
        }
        if (a.classNumber == null) return 1; // Move non-classNumber options to the end
        if (b.classNumber == null) return -1;
        return 0;
      })
      .map(({ classNumber, ...rest }) => rest); // Remove the extra classNumber property
  
      // Filter out any nulls, though this shouldn't occur given the above logic
      const filteredOptions = options.filter((option) => option !== null);
  
      filteredOptions.unshift({ value: "", label: "Select" }); // Add first element
      setClassOptions(filteredOptions); // Update state
      setClassOptionsIsLoading(false); // Set loading to false
      console.log('Class options', filteredOptions);
    }
  }, [listData]);

  const boardOptions = [
    { value: "", label: "Select" },
    { value: "CBSE", label: "CBSE" },
    { value: "ICSE", label: "ICSE" },
    // { value: "IGCSE", label: "IGCSE" },
    // { value: "State", label: "State Board" },
  ];

  const schoolOptions = [
      { value: "", label: "Select" },
      // { value: "Mahadevi Birla Shishu Vihar", label: "Mahadevi Birla Shishu Vihar" },
      // { value: "Success Point", label: "Success Point" }
      // { value: "Jain Coaching Institute", label: "Jain Coaching Institute" },
      { value: "Success Point Classes", label: "Success Point Classes" }
  ];
const sectionOptions = [
  { value: "", label: "Select" },
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
];

  const languageOptions = [
    { value: "", label: "Select" },
    { value: "English", label: "English" },
    { value: "Hindi", label: "Hindi" },
  ];

  //methods
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Regular expression to check if the name contains only alphabets
    const nameRegex = /^[A-Za-z\s]+$/;

    if (!nameRegex.test(formData.fullName)) {
      ShowToast({
        type: "error",
        message: "Name should only contain alphabets.",
        position: "top-right",
        autoClose: 5000,
      });
      // Set the text in the name field to an empty string
      setFormData({ ...formData, fullName: "" });
      return;
    }

    try {
      const response = await registerUser(formData).unwrap();
      if (response.statusCode === 200) {
        localStorage.clear();
        const keysToRemove = ["remainingTime", "showIntro"];
        removeLocalStorageItems(keysToRemove);
        dispatch(setUserInfoTrue());
        dispatch(setUserDetails(formData));
        window.open("/", "_blank", "noopener,noreferrer");
      } else if (response.statusCode === 201 || response.statusCode === 500) {
        throw new Error(response.status || "Something went wrong");
      }
    } catch (err) {
      console.error("Failed to register user:", err);
      ShowToast({
        type: "error",
        message: err.message,
        position: "top-right",
        autoClose: 5000,
      });
      setFormData({ ...formData, email: "" });
    }
  };

  const handleButtonClick = () => {
    ShowToast({
      type: "error",
      message: "This is a error message!",
      position: "top-right",
      autoClose: 5000,
    });
  };

  return (
    isMobileView ? (
      <div className="registration_container">
        <div className="main_container_mobile">
          <img src="/assessli_b.png" alt="logo" className="logo" />
          <div>
            <h1>Register</h1>
            <h6>Let's know you better before we get started...</h6>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="same-line-input">
              <InputBox
                className="input-fields"
                type="text"
                label="Full Name"
                name="fullName"
                id="full_name"
                placeholder="Enter your full name"
                value={formData.fullName}
                onChange={handleChange}
              />
              <InputBox
                className="input-fields"
                type="text"
                label="Roll Number"
                name="rollNumber"
                id="roll_number"
                placeholder="Enter your roll number"
                value={formData.rollNumber}
                onChange={handleChange}
              />
            </div>
            <div className="same-line-input">
              {/* <InputBox
                type="text"
                label="School Name"
                name="schoolName"
                id="school_name"
                placeholder="Enter your school name"
                value={formData.schoolName}
                onChange={handleChange}
              /> */}
              <SelectBox
                label="School Name"
                name="schoolName"
                id="school_name"
                options={schoolOptions}
                value={formData.schoolName}
                onChange={handleChange}
              />
              <SelectBox
                label="Board"
                name="board"
                id="board"
                options={boardOptions}
                value={formData.board}
                onChange={handleChange}
              />
              <SelectBox
                label="Class"
                name="classID"
                id="class"
                options={classoptionsisLoading ? [{ value: "", label: "Please Select Board" }] : classoptions}
                value={formData.classID}
                onChange={handleChange}
              />
              <SelectBox
                label="Section"
                name="section"
                id="section"
                options={sectionOptions}
                value={formData.section}
                onChange={handleChange}
              />
            </div>
            <div className="same-line-input">
            <label className="phone_input_label">
                Guardian Phone Number
                  <div className="phone_input">
                    <div className="country_code">
                      <img src={IndiaFlag} alt="country_flag" />
                      <span>+91</span>
                    </div>
                    <input
                      type="tel"
                      className="input_box"
                      placeholder="Enter guardian phone number"
                      style={{ width: "100%" }}
                      name="guardianPhoneNo"
                      id="guardianPhoneNo"
                      value={formData.guardianPhoneNo}
                      onChange={handleChange}
                    />
                  </div>
                </label>
            {/* --- */}
              {/* <label className="phone_input_label">
                Phone Number
                <div className="phone_input">
                  <div className="country_code">
                    <img src={IndiaFlag} alt="country_flag" />
                    <span>+91</span>
                  </div>
                  <input
                    type="tel"
                    className="input_box"
                    placeholder="Enter your phone number"
                    style={{ width: "100%" }}
                    name="phoneNo"
                    id="phone_number"
                    value={formData.phoneNo}
                    onChange={handleChange}
                  />
                </div>
              </label> */}
              {/* <SelectBox
                label="Board"
                name="board"
                id="board"
                options={boardOptions}
                value={formData.board}
                onChange={handleChange}
              /> */}
            </div>
            <div className="same-line-input-email" style={{ display: 'flex', alignItems: 'flex-end' }}>
              <InputBox
                className="email-input"
                type="email"
                label="Email Id"
                name="email"
                id="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
              />
              <button className="btn" type="submit" disabled={!isFormValid}>
                {isLoading ? "Loading..." : "Sign Up"}
              </button>
            </div>
          </form>
          <img src="/Exams-bro.svg" alt="Illustration" className="registration-illustration" />
          <div className="footer_container">
            <p>© {new Date().getFullYear()} by OneOath</p>
          </div>
        </div>
      </div>
    ) : (
      <div className="registration_container">
        <div className="left_container">
          <img src="/assessli_b.png" alt="logo" className="logo" />
          <div className="illustrations">
            <img src="/Uploading-bro.svg" alt="Illustration" className="registration-illustration-clouds" />
            <img src="/Exams-bro.svg" alt="Illustration" className="registration-illustration" />
          </div>
        </div>
        <div className="right_container">
          <div>
            <h1>Register</h1>
            <h6>Let's know you better before we get started...</h6>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="same-line-input">
              <InputBox
                className="input-fields"
                type="text"
                label="Full Name"
                name="fullName"
                id="full_name"
                placeholder="Enter your full name"
                value={formData.fullName}
                onChange={handleChange}
              />
              <InputBox
                className="input-fields"
                type="text"
                label="Roll Number"
                name="rollNumber"
                id="roll_number"
                placeholder="Enter your roll number"
                value={formData.rollNumber}
                onChange={handleChange}
              />
            </div>
            <div className="same-line-input">
              {/* <InputBox
                type="text"
                label="School Name"
                name="schoolName"
                id="school_name"
                placeholder="Enter your school name"
                value={formData.schoolName}
                onChange={handleChange}
              /> */}
              <SelectBox
                label="Board"
                name="board"
                id="board"
                options={boardOptions}
                value={formData.board}
                onChange={handleChange}
              />
              <SelectBox
                label="School Name"
                name="schoolName"
                id="school_name"
                options={schoolOptions}
                value={formData.schoolName}
                onChange={handleChange}
              />
            </div>
            <div className="same-line-input">
              {/* <label className="phone_input_label">
                Phone Number
                <div className="phone_input">
                  <div className="country_code">
                    <img src={IndiaFlag} alt="country_flag" />
                    <span>+91</span>
                  </div>
                  <input
                    type="tel"
                    className="input_box"
                    placeholder="Enter your phone number"
                    style={{ width: "100%" }}
                    name="phoneNo"
                    id="phone_number"
                    value={formData.phoneNo}
                    onChange={handleChange}
                  />
                </div>
              </label> */}
              <SelectBox
                label="Class"
                name="classID"
                id="class"
                options={classoptionsisLoading ? [{ value: "", label: "Please Select Board" }] : classoptions}
                value={formData.classID}
                onChange={handleChange}
              />
              <SelectBox
                label="Section"
                name="section"
                id="section"
                options={sectionOptions}
                value={formData.section}
                onChange={handleChange}
              />            
            </div>
            <div style={{ display: "flex", gap: "5px" }}>
              <label className="phone_input_label" style={{ marginRight: "3px" }}>
                Guardian Phone Number
                  <div className="phone_input">
                    <div className="country_code">
                      <img src={IndiaFlag} alt="country_flag" />
                      <span>+91</span>
                    </div>
                    <input
                      type="tel"
                      className="input_box"
                      placeholder="Enter guardian phone number"
                      style={{ width: "100%" }}
                      name="guardianPhoneNo"
                      id="guardianPhoneNo"
                      value={formData.guardianPhoneNo}
                      onChange={handleChange}
                    />
                  </div>
                </label>
                
                {/* <label className="phone_input_label">
                  Phone Number
                  <div className="phone_input">
                    <div className="country_code">
                      <img src={IndiaFlag} alt="country_flag" />
                      <span>+91</span>
                    </div>
                    <input
                      type="tel"
                      className="input_box"
                      placeholder="Enter your phone number"
                      style={{ width: "100%" }}
                      name="phoneNo"
                      id="phone_number"
                      value={formData.phoneNo}
                      onChange={handleChange}
                    />
                  </div>
                </label> */}
            </div>
            <div className="same-line-input-email" style={{ display: 'flex', alignItems: 'flex-end'}}>
              <InputBox
                className="email-input"
                type="email"
                label="Guardian Email Id"
                name="email"
                id="email"
                placeholder="Enter guardian email"
                value={formData.email}
                onChange={handleChange}
              />
              <button className="btn" type="submit" disabled={!isFormValid}>
                {isLoading ? "Loading..." : "Sign Up"}
              </button>
            </div>
            <div>
            <p><small>Disclaimer: The information you provide will be used exclusively for internal and communication purposes. It will not be shared or used for marketing activities.</small></p>
            </div>
          </form>
          <div className="footer_container">
            <p>© {new Date().getFullYear()} by OneOath</p>
          </div>
        </div>
      </div>
    )
  );
};

export default Register;
