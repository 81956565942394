import React, { useEffect, useState } from "react";
import logoDark from "../../assets/images/branding/logo_dark.png";
import { removeLocalStorageItems } from "../../utils/commonHelper";
import { useSelector } from "react-redux";
import { useDownloadFileQuery } from "../../apiSlices/question_submission_ApiSlice";

const AssesmentIntro = ({ toggleFullScreen }) => {
  const { userDetails } = useSelector((state) => state.authReducer);
  const [isConsent, setIsConsent] = useState(false);
  const [timeText, setTimeText] = useState('');
  const [isImagesFetched, setIsImagesFetched] = useState(false);

  const payload = {
    classID: userDetails?.classID,
    board: userDetails?.board,
    language: userDetails?.language
  }
  const questionsJSON = useDownloadFileQuery(payload);
  const assessmentDuration = parseInt(questionsJSON?.data?.body?.assessment_duration, 10);

  // Function to convert Blob to Base64
  async function convertBlobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
  
  // Function to fetch a single image
  async function fetchSingleImage(imageKey) {
    // Check if the URL is a static URL
    const isStaticURL = imageKey.startsWith('https://');
  
    const assessment_id = `assessment_${userDetails.classID.split('_')[1]}`;
    const response = await fetch(isStaticURL ? imageKey : `https://prod-api-server-318620872792.us-west1.run.app//board-download-question-images?board=${userDetails.board}&imageKey=${imageKey}&assessmentID=${assessment_id}&language=${userDetails.language}`, {
      method: "GET",
      // No need to set headers for static URLs
      headers: isStaticURL ? {} : {
        // No need to set Content-Type for image fetching
      },
    });
  
    if (!response.ok) {
      throw new Error(`Failed to fetch image at ${imageKey}`);
    }
  
    const imageBlob = await response.blob();
    return convertBlobToBase64(imageBlob);
  }
  
  async function fetchMultipleImages(sections) {
    // Create a new array to store the image URLs with only the required fields
    const imageURLs = sections.map((section) => ({
      category: section.category, // Keep the category
      questions: section.questions.map((question) => ({
        question_id: question.question_id, // Keep question ID
        question_image_url: question.question_image_url
          ? (question.question_image_url.startsWith('https://') ? question.question_image_url : null) // Directly add static URL or set to null
          : null,
        options: question.options.map((option) => ({
          option: option.option, // Keep option text
          option_image_url: option.option_image_url
            ? (option.option_image_url.startsWith('https://') ? option.option_image_url : null) // Directly add static URL or set to null
            : null,
        })),
      })),
    }));
  
    // Iterate over the sections array
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      
      // Iterate over the questions in each section
      for (let j = 0; j < section.questions.length; j++) {
        const question = section.questions[j];
        
        // Fetch and store question image if present and not a static URL
        if (question.question_image_url) {
          const base64QuestionImage = await fetchSingleImage(question.question_image_url);
          imageURLs[i].questions[j].question_image_url = base64QuestionImage;
        }
        
        // Iterate over the options in each question
        for (let k = 0; k < question.options.length; k++) {
          const option = question.options[k];
  
          // Fetch and store option image if present and not a static URL
          if (option.option_image_url) {
            const base64OptionImage = await fetchSingleImage(option.option_image_url);
            imageURLs[i].questions[j].options[k].option_image_url = base64OptionImage;
          }
        }
      }
    }
  
    // Return the updated object with base64 image URLs and only necessary fields
    return imageURLs;
  }

  // Execution Function
  async function init() {
    try {
      const sections = questionsJSON?.data?.body?.questionsListAsPerCategory;
      const imageURLS = await fetchMultipleImages(sections);
      localStorage.setItem('imageURLS', JSON.stringify(imageURLS));
      if(imageURLS){
        setIsImagesFetched(true);
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  }

  const convertTimeToText = async (seconds) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
      
        let hoursText = '';
        let minutesText = '';
      
        if (hours > 1) {
          hoursText = `${hours} hours`;
        } else if (hours === 1) {
          hoursText = '1 hour';
        }
      
        if (minutes > 1) {
          minutesText = `${minutes} minutes`;
        } else if (minutes === 1) {
          minutesText = '1 minute';
        }
      
        if (hoursText && minutesText) {
          resolve(`${hoursText} and ${minutesText}`);
        } else if (hoursText) {
          resolve(hoursText);
        } else if (minutesText) {
          resolve(minutesText);
        }
      }, 2000);
    });
  };

  const handleStartAssessment = () => {
    if (isConsent) {
      toggleFullScreen();
    }
  };

  useEffect(() => {
    const keysToRemove = ["showIntro"];
    removeLocalStorageItems(keysToRemove);
  }, []);

  useEffect(() => {
    const fetchTimeText = async () => {
      const text = await convertTimeToText(assessmentDuration);
      setTimeText(text);
    };

    fetchTimeText();
    
    // Fetch multiple images (replace with actual image keys)
    // const imageKeys = [
    //   "images/question1/question1.jpg",
    //   "images/question1/option_images/option_1.png",
    //   "images/question1/option_images/option_2.png",
    //   "images/question1/option_images/option_3.png",
    //   "images/question1/option_images/option_4.png",
    //   "images/question1/option_images/option_5.png",
    // ];
    init();
  }, [assessmentDuration]);

  if (timeText === '' || !isImagesFetched) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Loading your Assessment, please wait...
      </div>
    );
  }

  return (
    <div className="content-wrapper">
      <div className="container-fluid flex-grow-1 container-p-y">
        <div
          className="row gap-3"
          style={{ padding: "0rem", justifyContent: "space-between" }}
        >
          <div className="">
            <div
              className="row p-3 justify-content-center align-items-center"
              style={{ color: "#000000" }}
            >
              <span
                className="justify-content-center align-items-center"
                style={{ fontSize: "x-large" }}
              >
                <img src={logoDark} alt="Logo" height="32px" width="32px" />{" "}
                <b>Assessli</b>
              </span>
            </div>
          </div>
        </div>

        <div
          className="scroller_wrapper"
          style={{ height: "450px", overflowY: "scroll" }}
        >
          <div className="container mt-4" style={{ textAlign: "left" }}>
            <h2>Assessment Instructions</h2>
            <p>
              <strong>Exam Conduct:</strong>
            </p>
            <ol style={{ display: "flex", flexDirection: "column", gap: "4px", listStyleType: "disc" }}>
              <li>Ensure a quiet and distraction-free environment for the duration of the exam.</li>
              <li>Do not communicate with anyone during the assessment, including through messaging or social media platforms.</li>
              <li>Any attempt to cheat or use unauthorized materials will result in immediate disqualification.</li>
            </ol>
            <p>
              <strong>Exam Instructions:</strong>
            </p>
            <ol style={{ display: "flex", flexDirection: "column", gap: "4px", listStyleType: "disc" }}>
              <li>Read all questions carefully before answering.</li>
              <li>The duration of this exam is <strong>{timeText}</strong>. Manage your time wisely to complete all sections within the allocated time frame.</li>
              <li>Save your progress periodically to prevent loss of work.</li>
              <li>Once started, the timer will continue to run even if you close the browser window.</li>
              <li>Note that the exam will automatically submit when the allotted time elapses. Ensure all responses are finalized and submitted before this time. Late submissions will not be accepted.</li>
              <li><strong>Provide a clear and concise Justification for each of your responses. Your Justifications will offer valuable insights into your reasoning, decision-making abilities, and personality traits, aiding us in a more comprehensive assessment.</strong></li>
            </ol>
            <label htmlFor="declaration">
              <input
                type="checkbox"
                name="declaration"
                id="declaration"
                required={true}
                value={isConsent}
                onChange={(e) => setIsConsent(e.target.checked)}
              />{" "}
              I hereby declare that I will abide by the rules and regulations set forth for this assessment.
            </label>
          </div>
        </div>
        <div
          style={{ marginTop: "40px", display: "flex", alignItems: "start", justifyContent: "start" }}
        >
          <button
            className="btn btn-primary waves-effect waves-light"
            disabled={!isConsent}
            onClick={() => toggleFullScreen()}
          >
            Start Assessment
          </button>
        </div>
      </div>
      <div className="content-backdrop fade"></div>
    </div>
  );
};

export default AssesmentIntro;
